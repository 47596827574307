import ApplicationController from 'modules/application_controller';
import canonical from 'plugins/string/canonical';
import checkDelayedResult from 'plugins/utilities/check_delayed_result';
import defer from 'plugins/utilities/defer';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import toParamsQueryString from 'plugins/utilities/to_params_query_string';

export default class extends ApplicationController {
  static get targets() {
    return ['spinnerTemplate', 'errorTemplate', 'header', 'footer', 'empty', 'content', 'item'];
  }

  static get values() {
    return {
      url: {
        type: String,
        default: null,
      },
      hasFilter: {
        type: Boolean,
        default: false,
      },
      delayedResult: {
        type: Boolean,
        default: false,
      },
    };
  }

  initialize() {
    this.fetchRequest = null;
    this.filterData = null;
    this.clientSideFilterData = null;
  }

  connect() {
    this.showSpinner();

    if (this.isTurboPreview) return;

    if (!this.hasFilterValue) {
      this.load();
    }
  }

  disconnect() {
    this.filterData = null;
    this.clientSideFilterData = null;

    if (this.fetchRequest) {
      this.fetchRequest.abortController.abort();
      this.fetchRequest = null;
    }
  }

  itemTargetConnected() {
    this.toggleEmptyTarget();
  }

  itemTargetDisconnected() {
    this.toggleEmptyTarget();
  }

  reload(event, secretReload = false) {
    if (event) {
      event.preventDefault();
    }

    if (this.hasFilterValue) {
      this.load({ filter: this.filterData }, secretReload);
    } else {
      this.load({}, secretReload);
    }
  }

  updateFromEvent({ detail: { reload = false } } = {}) {
    if (!reload) return;

    this.load();
  }

  load({ filter: filterData = this.filterData, rerenderFilter = false } = {}, secretLoad = false) {
    if (this.fetchRequest) {
      this.fetchRequest.abortController.abort();
      this.fetchRequest = null;
    }

    this.filterData = filterData;

    if (!secretLoad) {
      this.showSpinner();
    }

    this.dispatchItemsChange({ clientSideChange: false });

    const params = {};

    if (this.hasFilterValue) {
      params.rerender_filter = rerenderFilter;

      if (isVariableDefinedNotNull(this.filterData)) {
        params.filter = {};

        for (const [key, value] of Object.entries(this.filterData)) {
          if (value === 'NONE' || value === '') {
            continue;
          }

          let normalizedKey = key;

          if (Array.isArray(value) && normalizedKey.endsWith('[]')) {
            normalizedKey = normalizedKey.slice(0, -2);
          }

          params.filter[normalizedKey] = value;
        }
      }
    }

    for (const key of Object.keys(this.additionalLoadParams)) {
      params[key] = this.additionalLoadParams[key];
    }

    const query = toParamsQueryString(params);
    const fetchAbortController = new AbortController();

    this.dispatch('loading', { detail: { secretLoad, params } });

    const handleStream = (html) => {
      this.beforeRenderResult();
      window.Turbo.renderStreamMessage(html);

      defer(() => {
        this.toggleEmptyTarget();
        this.dispatch('loaded', { detail: { secretLoad, params } });
        this.afterRenderResult();
        this.dispatchItemsChange({ clientSideChange: false });
      });
    };

    let url = this.urlValue;

    if (query && query !== '') {
      if (url.includes('?')) {
        url += `&${query}`;
      } else {
        url += `?${query}`;
      }
    }

    this.fetchRequest = fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: this.acceptType,
      },
      signal: fetchAbortController.signal,
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 400) {
          throw new Error(response.statusText);
        }

        if (this.delayedResultValue) {
          response.json().then((result) => {
            if (!result.delayed_result_id) {
              throw new Error('Loading list from from delayed result failed: No delayed result ID.');
            }

            checkDelayedResult(
              result.delayed_result_id,
              (delayedResult) => {
                if (delayedResult.success) {
                  handleStream(delayedResult.content_html);
                } else if (result.errors) {
                  throw new Error('Loading list from from delayed result failed:', result.errors);
                }
              },
              500,
            );
          });

          return null;
        }

        return response.text();
      })
      .then((html) => {
        if (html) {
          handleStream(html);
        }

        this.fetchRequest = null;
      })
      .catch((error) => {
        if (fetchAbortController.signal.aborted) {
          return;
        }

        console.warn(error);
        this.renderListError();
        this.fetchRequest = null;
      });

    this.fetchRequest.abortController = fetchAbortController;
  }

  beforeRenderResult() {}

  afterRenderResult() {}

  filter({ detail }) {
    this.load(detail, false);
  }

  filterClientSide(event) {
    if (event) {
      this.clientSideFilterData = event.detail.filter;
    }

    if (!this.clientSideFilterData) return;

    for (const item of this.itemTargets) {
      if (item.dataset.clientSideFiltered !== 'true') {
        continue;
      }

      let json = JSON.parse(item.dataset.filterValues);
      if (!Array.isArray(json)) {
        json = [json];
      }

      let found = false;
      let noFilter = true;

      for (const filterValue of Object.values(this.clientSideFilterData)) {
        const canonicalFilterValue = canonical(filterValue);
        if (!canonicalFilterValue || canonicalFilterValue === '') {
          continue;
        }

        noFilter = false;

        const someFound = json.some((v) => {
          if (!isVariableDefinedNotNull(v)) return false;
          if (typeof v === 'string') return canonical(v).includes(canonicalFilterValue);
          if (v.toString && v.toString() === canonicalFilterValue) return true;

          return false;
        });

        if (someFound) {
          found = true;
          break;
        }
      }

      const visible = noFilter || found;

      item.hidden = !visible;
    }

    if (!this.fetchRequest) {
      this.dispatchItemsChange({ clientSideChange: true });
    }
  }

  renderListError() {
    this.setContent(this.errorTemplateTarget.innerHTML);
  }

  setContent(html) {
    this.contentTarget.innerHTML = html;
  }

  showSpinner() {
    this.setContent(this.spinnerTemplateTarget.innerHTML);
  }

  dispatchItemsChange({ clientSideChange }) {
    this.dispatch('itemsChange', { detail: { clientSideChange, itemsValues: this.itemsValues } });
  }

  toggleEmptyTarget() {
    const isEmpty = this.itemTargets.length === 0;

    if (this.hasEmptyTarget) {
      this.emptyTarget.hidden = !isEmpty;
    }

    if (this.hasHeaderTarget) {
      this.headerTarget.hidden = isEmpty;
    }

    if (this.hasFooterTarget) {
      this.footerTarget.hidden = isEmpty;
    }
  }

  get itemsValues() {
    const values = [];

    for (const item of this.itemTargets) {
      if (!item.hidden) {
        const itemController = this.findControllerOnElement(item);
        if (itemController) values.push(itemController.itemValue);
      }
    }

    return values;
  }

  get additionalLoadParams() {
    return {};
  }

  get acceptType() {
    if (this.delayedResultValue) {
      return 'text/javascript';
    }

    return 'text/vnd.turbo-stream.html';
  }

  get cookieName() {
    return this.identifier;
  }
}
